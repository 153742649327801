import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Link, navigate } from "gatsby"
import LogRocket from 'logrocket'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import ConfirmedButton from "../components/confirmedButton"

import "../styles/global.css"

const orgKey = 'org';
const appKey = 'app';

const mapDispatchToProps = dispatch => {
  return { set: (value) => dispatch({ type: `set`, value }) }
};

const Input = ({ set }) => {
  const [inputValue, setInputValue] = useState('');

  return(
    <div>
      <input onChange={event => setInputValue(event.target.value)} placeholder="Redux State Value"/>
      <br/>
      <ConfirmedButton onClick={() => set(inputValue)} text={"Commit to store"} />
    </div>
  )
}

const ConnectedInput = connect(null, mapDispatchToProps)(Input)

const npmModule = (appSlug, orgSlug) => `
  $ npm i --save logrocket

  import LogRocket from 'logrocket';
  LogRocket.init('${appSlug}/${orgSlug}');
`;

const scriptTag = (appSlug, orgSlug) => `
  <script src="https://cdn.logrocket.io/LogRocket.min.js"></script>
  <script>window.LogRocket && window.LogRocket.init('${appSlug}/${orgSlug}');</script>
`;

const InstallCode= ({ orgSlug, appSlug, code }) => {
  return (
    <SyntaxHighlighter language="javascript" style={docco}>
      {code(orgSlug, appSlug)}
    </SyntaxHighlighter>
  );
}

const IndexPage = () => {
  const [recordingUrl, setRecordingUrl] = useState('');
  const [orgSlug, setOrgSlug] = useState('');
  const [appSlug, setAppSlug] = useState('');

  const hasOrg = orgSlug && appSlug;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const getFromParamsOrStorage = (key) => urlParams.get(key) || localStorage.getItem(key);
    const org = getFromParamsOrStorage(orgKey);
    const app = getFromParamsOrStorage(appKey);
    if (org && app) {
      localStorage.setItem(orgKey, org);
      setOrgSlug(org);
      localStorage.setItem(appKey, app);
      setAppSlug(app);
    }
    LogRocket.init(`demo-kdz7k/logrocket-preview`, {
      dashboardHost: 'https://demo.logrocket.com',
      serverURL: 'https://demo-i.lr-ingest.io/i',
      network: {
        responseSanitizer: response => {
          if (!response.url.includes('secret')) return response;
          const responseObject = JSON.parse(response.body);
          responseObject.token = "XXXX";
          response.body = JSON.stringify(responseObject);
          return response;
        },
      },
   });
    LogRocket.getSessionURL((url) => {
      const formattedUrl = new URL(url);
      const sessionPath = formattedUrl.pathname.split('/').slice(4).join('/');
      formattedUrl.pathname = `/go/${sessionPath}`;
      formattedUrl.searchParams.delete('t');
      formattedUrl.searchParams.append('type', 'developer');
      setRecordingUrl(formattedUrl.href);
    });
  }, []);

  return (
    <Layout>
      <SEO title="Home" />
      <h1>Session Recording</h1>
      <div className="about">
        <h2>About</h2>
        <ul style={{marginBottom: 0}}>
          <li>This site showcases the recording functionality of LogRocket.</li>
          <li>Browse and interact with this site to generate an example of LogRocket session playback.</li>
          <li style={{marginBottom: 0}}>Experiment with the page below to see the activity showcased in LogRocket.</li>
        </ul>
      </div>
      {/* <hr style={{margin: 0}}/> */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '3rem',
        }}
        className="sticky purple">
        <span style={{ marginLeft: '2rem', paddingTop: '.15rem' }}>Done experimenting?</span>
        <button onClick={() => window.open(recordingUrl, "_blank")} style={{margin: '.4rem'}} className="invert">view the session here</button>
      </div>
      <hr/>
      <h4>Input</h4>
      <span className="row">
        <span className="column">
          <p style={{marginBottom: '0.5rem'}} >Text input appears in recordings:</p>
          <input placeholder="type something!"></input>
        </span>
        <span className="column">
          <p style={{marginBottom: '0.5rem'}} >Input content can be redacted:</p>
          <input data-private placeholder="this text will be redacted"></input>
        </span>
      </span>
      <hr/>
      <h4>Network Activity</h4>
      <span className="row">
        <span className="column">
          <p>Network activity is visible in the top pane of the <a href={ recordingUrl } target="_blank">developer tab</a>.</p>
          <ConfirmedButton onClick={() => fetch('ping.json')} text={"Make Request"} />
        </span>
        <span className="column">
          <p>Network requests can be filtered to protect sensitive information: </p>
          <ConfirmedButton onClick={() => fetch('secret.json')} text={"Make Filtered Request"} />
          <p>If you expand requests made with this button during playback, the "token" values in their bodies will be redacted.</p>
        </span>
      </span>
      <hr/>
      <h4>Images</h4>
      <span className="row">
        <span className="column">
          <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
            <Image path="rocket.png" />
          </div>
        </span>
        <span style={{display: 'flex'}} className="column">
          <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
            <div data-private="block">
              <Image path="secret_rocket.png" />
            </div>
          </div>
          <span>
            <p>Like inputs, images can be redacted.</p>
            <p style={{ display: 'inline', marginRight: 0 }}>In fact, anything can. </p>
            (<div data-private style={{ display: 'inline' }}>This text will be redacted as well.</div>)
          </span>
        </span>
      </span>
      <hr/>
      <h4>Navigation</h4>
      <p>LogRocket can record long-running sessions on single page apps, or apps with traditional navigation.</p>
      <p><Link to="/page-2/">Go to page 2</Link></p>
      <hr/>
      <h4>Warnings and Errors</h4>
      <p> Logrocket captures warning and errors on your site. You can view them in
        the <a href={ recordingUrl } target="_blank">lower-right logs pane</a>.</p>
      <button onClick={() => { throw "Boom!" } }>Throw Error</button>
      <hr/>
      <h4>Redux/Vuex/NgRx</h4>
      <p>
        Logrocket has <a target="blank" href="https://docs.logrocket.com/docs/react-plugin">built
        in support</a> for your state manager of choice. You can view mutations in
        the <a href={ recordingUrl } target="_blank">logs pane</a> as well.
       </p>
      <ConnectedInput />
      <hr/>
      { hasOrg && <span>
        <h2>Want to try it in your app?</h2>
        <p>Install from the npm module: </p>
        <InstallCode orgSlug={orgSlug} appSlug={appSlug} code={npmModule} />
        <p>Or use a scipt tag: </p>
        <InstallCode orgSlug={orgSlug} appSlug={appSlug} code={scriptTag} />
        <p>and sessions will start recording!</p>
      </span> }
      { !hasOrg && <span>
        <span className="row">
          <span className="column center-flex">
            <h2 style={{margin: 0}}>Want to try it in your app?</h2>
          </span>
          <span className="column center-flex" style={{justifyContent: 'flex-end'}}>
            <button
              onClick={() => window.open('https://app.logrocket.com/', "_blank")}
              className="large"
              style={{marginRight: '2rem'}}
            >
              Get Started Free
            </button>
          </span>
        </span>
      </span> }
      <hr/>
    </Layout>
  )
}

export default IndexPage
