import React, { useState } from "react"
import {Checkmark} from 'react-checkmark'

let timeout = null;

const ConfirmedButton = ({ onClick, text }) => {
  const [clicked, setClicked] = useState(false);
  return (
    <span>
      <button onClick={() => {
        clearTimeout(timeout);
        setClicked(true);
        onClick();
        timeout = setTimeout(setClicked, 3000, false);
      }}>{text}</button>
      <span class={clicked ? 'fade-able fade' : 'fade-able reset-fade'}>
        { clicked && <Checkmark size={35}/> }
      </span>
    </span>
  )
}

export default ConfirmedButton
